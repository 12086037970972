<template>
  <v-container>
    <Datatable ref="cashRecyclerSettingsDataTable" no-excel-export :show-select-checkbox="false"
               :api-endpoint="ENDPOINTS.DATATABLES.MODULESETTINGS.CASHRECYCLERS"
               show-edit-buttons
               show-delete-buttons
               :datatable-headers="dataTableHeaders"
               @editEntry="editEntry"
               @deleteEntry="deleteEntry"
               @deleteEntries="deleteData"/>
  </v-container>
</template>

<script>
import {mapState} from "vuex";
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import Datatable from "@/components/datatable/Datatable";

export default {
  name: "CashRecyclerSettingsComponent",
  components: {Datatable},
  data() {
    return {
      ENDPOINTS,
      uuid: null,
    }
  },
  computed: {
    ...mapState([
      "cashierIDs",
    ]),
    dataTableHeaders() {
      return [
        {
          text: "ID",
          value: "id",
          sortable: false,
          hide: true,
        },
        {
          text: this.$t('generic.lang_alias'),
          value: "alias",
        },
        {
          text: this.$t('generic.lang_ipAddress'),
          value: "ipAddress",
        },
        {
          text: this.$t('generic.lang_cashierID'),
          value: "cashierID",
        },
      ]
    },
    cashierIDS() {
      return this.cashierIDs.availableCashierIDs.map(cashier => {
        return {id: cashier, name: 'Kasse ' + cashier}
      })
    }
  },
  methods: {
    deleteData: function (idsToDelete = []) {
      let self = this;
      this.$swal({
        title: this.$t('settings.lang_deleteCashRecyclerSetting'),
        text: this.$t('generic.lang_deleteConfirmationMSG') + '?',
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
            idsToDelete = [];
            idsToDelete.push(this.uuid);
          }
          this.axios.post(ENDPOINTS.SETTINGS.MODULESETTINGS.CASHRECYCLER.DELETE, {
            ids: idsToDelete,
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_actionSuccessful'),
                color: "success"
              });
              self.$refs.cashRecyclerSettingsDataTable.getDataFromApi();
              self.$refs.cashRecyclerSettingsDataTable.resetSelectedRows();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading,
      });
    },

    deleteEntry(entry) {
      this.uuid = entry.id;
      this.deleteData();
    },
    editEntry(entry){
      this.$router.push({name: 'settings.moduleSettings.cashrecycler.edit', params: {uuid: entry.id}})
    }
  },
  mounted() {

  }


}
</script>

<style scoped>

</style>