<template>
  <div>
    <page-title :heading="$t('settings.lang_cashRecyclerSettings')" :subheading="$t('settings.lang_cashRecyclerSettings')"
                :icon=icon :show-add-button="true" :permission-add="true"></page-title>
    <div class="app-main__inner">
      <CashRecyclerSettingsComponent/>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../../../Layout/Components/PageTitle.vue";
import CashRecyclerSettingsComponent
  from "../../../../components/settings/moduleSettings/cashrecycler/CashRecyclerSettingsComponent";

export default {
  components: {
    CashRecyclerSettingsComponent,
    PageTitle,
  },

  data: () => ({
    icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
  })
}
</script>